<template>
  <div class="container" :style="height">
    <div
      class="title"
    >{{({"1":"产品服务","2":"解决方案","3":"客户合作","4":"关于我们"}[this.$route.query.articleType])}}</div>
    <div class="shouhou">
      <div v-if="this.$route.query.articleType==1" v-html="article1"></div>
      <div v-if="this.$route.query.articleType==2" v-html="article2"></div>
      <div v-if="this.$route.query.articleType==3" v-html="article3"></div>
      <div v-if="this.$route.query.articleType==4" v-html="article4"></div>
    </div>
  </div>
</template>
<script>
// 1,2,3,4
import {
  Serviceslist,
  Solutlist,
  Customerlist,
  Aboutlist
} from "@/assets/js/api";
export default {
  name: "buynow",
  data() {
    return {
      height: { height: "" },
      article1: "",
      article2: "",
      article3: "",
      article4: ""
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    Serviceslist(articleType) {
      if (articleType == 1) {
        Serviceslist({}).then(result => {
          if (result.code == 0) {
            this.article1 = result.appSysProductServices.text;
          }
        });
      } else if (articleType == 2) {
        Solutlist({}).then(result => {
          if (result.code == 0) {
            this.article2 = result.appSysSolution.text;
          }
        });
      } else if (articleType == 3) {
        Customerlist({}).then(result => {
          if (result.code == 0) {
            this.article3 = result.appSysCustomerCooperation.text;
          }
        });
      } else if (articleType == 4) {
        Aboutlist({}).then(result => {
          if (result.code == 0) {
            this.article4 = result.appSysAfterSale.text;
          }
        });
      }
    }
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    if (window.loading) {
      window.loading.close();
    }
    window.removeEventListener("resize", this.getHight);
  },
  mounted: function() {
    this.Serviceslist(this.$route.query.articleType);
  },
  beforeUpdate: function() {
    this.Serviceslist(this.$route.query.articleType);
  }
};
</script>
